/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHomeDetails = /* GraphQL */ `
  query GetHomeDetails {
    getHomeDetails {
      faqs {
        id
        title
        description
        statusCode
        createdAt
        updatedAt
      }
      whos {
        id
        title
        subtitle
        image
        description
        statusCode
        createdAt
        updatedAt
      }
      clients {
        id
        title
        subtitle
        image
        rating
        description
        statusCode
        createdAt
        updatedAt
      }
    }
  }
`;
export const getPageDetails = /* GraphQL */ `
  query GetPageDetails($slug: String!) {
    getPageDetails(slug: $slug) {
      id
      slug
      title
      image
      baliseTitle
      description
      keywords
      content
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const getArticleDetails = /* GraphQL */ `
  query GetArticleDetails($slug: String!) {
    getArticleDetails(slug: $slug) {
      id
      blogID
      slug
      balise_title
      title
      image
      cover
      description
      keywords
      content
      statusCode
      createdAt
      bottomPopupMessage
      bottomPopupButton
      bottomPopupUrl
      imageAlt
      coverAlt
      imageCloudinary
      coverCloudinary
      contentCloudinary
      score
      order
      duration
      bookmark
      template
      baliseDescription
      bottomPopupEnabled
      simulatorEnabled
      titleSimulator
      descriptionSimulator
      btnSimulator
      urlSimulator
      imageTitle
      coverTitle
      contentBottomImageAlt
      contentBottomImageTitle
      updatedAt
      blog {
        id
        title
        baliseTitle
        description
        keywords
        slug
        image
        statusCode
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const getArticleTest = /* GraphQL */ `
  query GetArticleTest($slug: String!) {
    getArticleTest(slug: $slug)
  }
`;
export const getBlogDetails = /* GraphQL */ `
  query GetBlogDetails($slug: String!) {
    getBlogDetails(slug: $slug) {
      id
      title
      baliseTitle
      description
      keywords
      slug
      image
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const getSiteMap = /* GraphQL */ `
  query GetSiteMap {
    getSiteMap
  }
`;
export const sendReminderEmail = /* GraphQL */ `
  query SendReminderEmail {
    sendReminderEmail
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      title
      baliseTitle
      description
      keywords
      slug
      image
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        baliseTitle
        description
        keywords
        slug
        image
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      blogID
      slug
      balise_title
      title
      image
      cover
      description
      keywords
      content
      statusCode
      createdAt
      bottomPopupMessage
      bottomPopupButton
      bottomPopupUrl
      imageAlt
      coverAlt
      imageCloudinary
      coverCloudinary
      contentCloudinary
      score
      order
      duration
      bookmark
      template
      baliseDescription
      bottomPopupEnabled
      simulatorEnabled
      titleSimulator
      descriptionSimulator
      btnSimulator
      urlSimulator
      imageTitle
      coverTitle
      contentBottomImageAlt
      contentBottomImageTitle
      updatedAt
      blog {
        id
        title
        baliseTitle
        description
        keywords
        slug
        image
        statusCode
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listArticlesOrderByCreatedAt = /* GraphQL */ `
  query ListArticlesOrderByCreatedAt(
    $statusCode: StatusCode
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticlesOrderByCreatedAt(
      statusCode: $statusCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const getArticleRelated = /* GraphQL */ `
  query GetArticleRelated($id: ID!) {
    getArticleRelated(id: $id) {
      id
      relatedID
      articleID
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const listArticleRelateds = /* GraphQL */ `
  query ListArticleRelateds(
    $filter: ModelArticleRelatedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleRelateds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        relatedID
        articleID
        createdAt
        updatedAt
        article {
          id
          blogID
          slug
          balise_title
          title
          image
          cover
          description
          keywords
          content
          statusCode
          createdAt
          bottomPopupMessage
          bottomPopupButton
          bottomPopupUrl
          imageAlt
          coverAlt
          imageCloudinary
          coverCloudinary
          contentCloudinary
          score
          order
          duration
          bookmark
          template
          baliseDescription
          bottomPopupEnabled
          simulatorEnabled
          titleSimulator
          descriptionSimulator
          btnSimulator
          urlSimulator
          imageTitle
          coverTitle
          contentBottomImageAlt
          contentBottomImageTitle
          updatedAt
          blog {
            id
            title
            baliseTitle
            description
            keywords
            slug
            image
            statusCode
            createdAt
            updatedAt
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getArticleFAQ = /* GraphQL */ `
  query GetArticleFAQ($id: ID!) {
    getArticleFAQ(id: $id) {
      id
      articleID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listArticleFAQs = /* GraphQL */ `
  query ListArticleFAQs(
    $filter: ModelArticleFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleFAQs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleID
        title
        description
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticleComment = /* GraphQL */ `
  query GetArticleComment($id: ID!) {
    getArticleComment(id: $id) {
      id
      articleID
      fullname
      email
      comment
      isPublic
      statusCode
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
      replyComments {
        items {
          id
          articleCommentID
          comment
          statusCode
          isPublic
          createdAt
          updatedAt
          articleComment {
            id
            articleID
            fullname
            email
            comment
            isPublic
            statusCode
            createdAt
            updatedAt
            article {
              id
              blogID
              slug
              balise_title
              title
              image
              cover
              description
              keywords
              content
              statusCode
              createdAt
              bottomPopupMessage
              bottomPopupButton
              bottomPopupUrl
              imageAlt
              coverAlt
              imageCloudinary
              coverCloudinary
              contentCloudinary
              score
              order
              duration
              bookmark
              template
              baliseDescription
              bottomPopupEnabled
              simulatorEnabled
              titleSimulator
              descriptionSimulator
              btnSimulator
              urlSimulator
              imageTitle
              coverTitle
              contentBottomImageAlt
              contentBottomImageTitle
              updatedAt
              owner
            }
            owner
            replyComments {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const listArticleComments = /* GraphQL */ `
  query ListArticleComments(
    $filter: ModelArticleCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleID
        fullname
        email
        comment
        isPublic
        statusCode
        createdAt
        updatedAt
        article {
          id
          blogID
          slug
          balise_title
          title
          image
          cover
          description
          keywords
          content
          statusCode
          createdAt
          bottomPopupMessage
          bottomPopupButton
          bottomPopupUrl
          imageAlt
          coverAlt
          imageCloudinary
          coverCloudinary
          contentCloudinary
          score
          order
          duration
          bookmark
          template
          baliseDescription
          bottomPopupEnabled
          simulatorEnabled
          titleSimulator
          descriptionSimulator
          btnSimulator
          urlSimulator
          imageTitle
          coverTitle
          contentBottomImageAlt
          contentBottomImageTitle
          updatedAt
          blog {
            id
            title
            baliseTitle
            description
            keywords
            slug
            image
            statusCode
            createdAt
            updatedAt
          }
          owner
        }
        owner
        replyComments {
          items {
            id
            articleCommentID
            comment
            statusCode
            isPublic
            createdAt
            updatedAt
            articleComment {
              id
              articleID
              fullname
              email
              comment
              isPublic
              statusCode
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listArticleCommentsOrderByCreatedAt = /* GraphQL */ `
  query ListArticleCommentsOrderByCreatedAt(
    $statusCode: StatusCode
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleCommentsOrderByCreatedAt(
      statusCode: $statusCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleID
        fullname
        email
        comment
        isPublic
        statusCode
        createdAt
        updatedAt
        article {
          id
          blogID
          slug
          balise_title
          title
          image
          cover
          description
          keywords
          content
          statusCode
          createdAt
          bottomPopupMessage
          bottomPopupButton
          bottomPopupUrl
          imageAlt
          coverAlt
          imageCloudinary
          coverCloudinary
          contentCloudinary
          score
          order
          duration
          bookmark
          template
          baliseDescription
          bottomPopupEnabled
          simulatorEnabled
          titleSimulator
          descriptionSimulator
          btnSimulator
          urlSimulator
          imageTitle
          coverTitle
          contentBottomImageAlt
          contentBottomImageTitle
          updatedAt
          blog {
            id
            title
            baliseTitle
            description
            keywords
            slug
            image
            statusCode
            createdAt
            updatedAt
          }
          owner
        }
        owner
        replyComments {
          items {
            id
            articleCommentID
            comment
            statusCode
            isPublic
            createdAt
            updatedAt
            articleComment {
              id
              articleID
              fullname
              email
              comment
              isPublic
              statusCode
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getArticleReplyComment = /* GraphQL */ `
  query GetArticleReplyComment($id: ID!) {
    getArticleReplyComment(id: $id) {
      id
      articleCommentID
      comment
      statusCode
      isPublic
      createdAt
      updatedAt
      articleComment {
        id
        articleID
        fullname
        email
        comment
        isPublic
        statusCode
        createdAt
        updatedAt
        article {
          id
          blogID
          slug
          balise_title
          title
          image
          cover
          description
          keywords
          content
          statusCode
          createdAt
          bottomPopupMessage
          bottomPopupButton
          bottomPopupUrl
          imageAlt
          coverAlt
          imageCloudinary
          coverCloudinary
          contentCloudinary
          score
          order
          duration
          bookmark
          template
          baliseDescription
          bottomPopupEnabled
          simulatorEnabled
          titleSimulator
          descriptionSimulator
          btnSimulator
          urlSimulator
          imageTitle
          coverTitle
          contentBottomImageAlt
          contentBottomImageTitle
          updatedAt
          blog {
            id
            title
            baliseTitle
            description
            keywords
            slug
            image
            statusCode
            createdAt
            updatedAt
          }
          owner
        }
        owner
        replyComments {
          items {
            id
            articleCommentID
            comment
            statusCode
            isPublic
            createdAt
            updatedAt
            articleComment {
              id
              articleID
              fullname
              email
              comment
              isPublic
              statusCode
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listArticleReplyComments = /* GraphQL */ `
  query ListArticleReplyComments(
    $filter: ModelArticleReplyCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleReplyComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleCommentID
        comment
        statusCode
        isPublic
        createdAt
        updatedAt
        articleComment {
          id
          articleID
          fullname
          email
          comment
          isPublic
          statusCode
          createdAt
          updatedAt
          article {
            id
            blogID
            slug
            balise_title
            title
            image
            cover
            description
            keywords
            content
            statusCode
            createdAt
            bottomPopupMessage
            bottomPopupButton
            bottomPopupUrl
            imageAlt
            coverAlt
            imageCloudinary
            coverCloudinary
            contentCloudinary
            score
            order
            duration
            bookmark
            template
            baliseDescription
            bottomPopupEnabled
            simulatorEnabled
            titleSimulator
            descriptionSimulator
            btnSimulator
            urlSimulator
            imageTitle
            coverTitle
            contentBottomImageAlt
            contentBottomImageTitle
            updatedAt
            blog {
              id
              title
              baliseTitle
              description
              keywords
              slug
              image
              statusCode
              createdAt
              updatedAt
            }
            owner
          }
          owner
          replyComments {
            items {
              id
              articleCommentID
              comment
              statusCode
              isPublic
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      prospectID
      packageID
      customerID
      postalCode
      city
      isSigned
      isWantMail
      unPaid
      contractNumnber
      companyName
      companyAddress
      companyPhoneNumber
      userID
      asignedUser
      fromDate
      toDate
      extendDate
      hasTrial
      terminateDate
      note
      attachments
      orderID
      createdAt
      updatedAt
      owner
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      customer {
        id
        prospectID
        email
        phone_number
        address
        numberStreet
        additionalAddress
        zipCode
        city
        company_already_created
        packageID
        payment
        fullname
        statusCode
        verified
        contract
        identityFront
        identityBack
        createdAt
        createdAtTimestamp
        updatedAt
        owner
      }
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prospectID
        packageID
        customerID
        postalCode
        city
        isSigned
        isWantMail
        unPaid
        contractNumnber
        companyName
        companyAddress
        companyPhoneNumber
        userID
        asignedUser
        fromDate
        toDate
        extendDate
        hasTrial
        terminateDate
        note
        attachments
        orderID
        createdAt
        updatedAt
        owner
        order {
          id
          prospectID
          packageID
          customerID
          orderDate
          fromDate
          month
          year
          toDate
          taxAmount
          discountAmount
          totalAmount
          currency
          note
          orderStatus
          createdAt
          updatedAt
          owner
        }
        customer {
          id
          prospectID
          email
          phone_number
          address
          numberStreet
          additionalAddress
          zipCode
          city
          company_already_created
          packageID
          payment
          fullname
          statusCode
          verified
          contract
          identityFront
          identityBack
          createdAt
          createdAtTimestamp
          updatedAt
          owner
        }
        package {
          id
          order
          name
          price
          currency
          description
          note
          bestChoice
          button
          features
          buttonLoadMore
          colorButtonLoadMore
          code
          createdAt
          updatedAt
          steps {
            items {
              id
              packageID
              step
              summary
              features
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          options {
            items {
              id
              packageID
              price
              code
              name
              description
              statusCode
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      prospectID
      packageID
      customerID
      orderDate
      fromDate
      month
      year
      toDate
      taxAmount
      discountAmount
      totalAmount
      currency
      note
      orderStatus
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      orderID
      paidAmount
      currency
      month
      year
      isFullPaid
      invoiceDate
      invoiceAddress
      invoiceName
      description
      note
      paymentMethod
      customerStripeID
      invoiceStatus
      createdAt
      updatedAt
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        paidAmount
        currency
        month
        year
        isFullPaid
        invoiceDate
        invoiceAddress
        invoiceName
        description
        note
        paymentMethod
        customerStripeID
        invoiceStatus
        createdAt
        updatedAt
        order {
          id
          prospectID
          packageID
          customerID
          orderDate
          fromDate
          month
          year
          toDate
          taxAmount
          discountAmount
          totalAmount
          currency
          note
          orderStatus
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      prospectID
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      statusCode
      verified
      contract
      identityFront
      identityBack
      createdAt
      createdAtTimestamp
      updatedAt
      owner
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prospectID
        email
        phone_number
        address
        numberStreet
        additionalAddress
        zipCode
        city
        company_already_created
        packageID
        payment
        fullname
        statusCode
        verified
        contract
        identityFront
        identityBack
        createdAt
        createdAtTimestamp
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomerNote = /* GraphQL */ `
  query GetCustomerNote($id: ID!) {
    getCustomerNote(id: $id) {
      id
      customerID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomerNotes = /* GraphQL */ `
  query ListCustomerNotes(
    $filter: ModelCustomerNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomerStripe = /* GraphQL */ `
  query GetCustomerStripe($id: ID!) {
    getCustomerStripe(id: $id) {
      id
      customerID
      amount
      name
      chargesID
      payment_method
      receipt_url
      PaymentIntentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomerStripes = /* GraphQL */ `
  query ListCustomerStripes(
    $filter: ModelCustomerStripeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerStripes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        amount
        name
        chargesID
        payment_method
        receipt_url
        PaymentIntentID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCategoryDocument = /* GraphQL */ `
  query GetCategoryDocument($id: ID!) {
    getCategoryDocument(id: $id) {
      id
      order
      title
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCategoryDocuments = /* GraphQL */ `
  query ListCategoryDocuments(
    $filter: ModelCategoryDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order
        title
        statusCode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      categoryDocumentID
      order
      title
      file
      statusCode
      createdAt
      updatedAt
      categoryDocument {
        id
        order
        title
        statusCode
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryDocumentID
        order
        title
        file
        statusCode
        createdAt
        updatedAt
        categoryDocument {
          id
          order
          title
          statusCode
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getGoogleService = /* GraphQL */ `
  query GetGoogleService($id: ID!) {
    getGoogleService(id: $id) {
      id
      message
      data
      prospectID
      statusCode
      googleServiceType
      calendarId
      eventId
      startDate
      endDate
      summary
      description
      location
      guests
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGoogleServices = /* GraphQL */ `
  query ListGoogleServices(
    $filter: ModelGoogleServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoogleServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        data
        prospectID
        statusCode
        googleServiceType
        calendarId
        eventId
        startDate
        endDate
        summary
        description
        location
        guests
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listGoogleServiceByProspectID = /* GraphQL */ `
  query ListGoogleServiceByProspectID(
    $prospectID: ID
    $statusCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoogleServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoogleServiceByProspectID(
      prospectID: $prospectID
      statusCode: $statusCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        data
        prospectID
        statusCode
        googleServiceType
        calendarId
        eventId
        startDate
        endDate
        summary
        description
        location
        guests
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPackage = /* GraphQL */ `
  query GetPackage($id: ID!) {
    getPackage(id: $id) {
      id
      order
      name
      price
      currency
      description
      note
      bestChoice
      button
      features
      buttonLoadMore
      colorButtonLoadMore
      code
      createdAt
      updatedAt
      steps {
        items {
          id
          packageID
          step
          summary
          features
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
      options {
        items {
          id
          packageID
          price
          code
          name
          description
          statusCode
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const listPackages = /* GraphQL */ `
  query ListPackages(
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStepPackage = /* GraphQL */ `
  query GetStepPackage($id: ID!) {
    getStepPackage(id: $id) {
      id
      packageID
      step
      summary
      features
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listStepPackages = /* GraphQL */ `
  query ListStepPackages(
    $filter: ModelStepPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStepPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        packageID
        step
        summary
        features
        createdAt
        updatedAt
        package {
          id
          order
          name
          price
          currency
          description
          note
          bestChoice
          button
          features
          buttonLoadMore
          colorButtonLoadMore
          code
          createdAt
          updatedAt
          steps {
            items {
              id
              packageID
              step
              summary
              features
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          options {
            items {
              id
              packageID
              price
              code
              name
              description
              statusCode
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getOptionPackage = /* GraphQL */ `
  query GetOptionPackage($id: ID!) {
    getOptionPackage(id: $id) {
      id
      packageID
      price
      code
      name
      description
      statusCode
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listOptionPackages = /* GraphQL */ `
  query ListOptionPackages(
    $filter: ModelOptionPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptionPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        packageID
        price
        code
        name
        description
        statusCode
        createdAt
        updatedAt
        package {
          id
          order
          name
          price
          currency
          description
          note
          bestChoice
          button
          features
          buttonLoadMore
          colorButtonLoadMore
          code
          createdAt
          updatedAt
          steps {
            items {
              id
              packageID
              step
              summary
              features
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          options {
            items {
              id
              packageID
              price
              code
              name
              description
              statusCode
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getStaticPage = /* GraphQL */ `
  query GetStaticPage($id: ID!) {
    getStaticPage(id: $id) {
      id
      page
      slug
      title
      image
      description
      baliseTitle
      keywords
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listStaticPages = /* GraphQL */ `
  query ListStaticPages(
    $filter: ModelStaticPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaticPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        page
        slug
        title
        image
        description
        baliseTitle
        keywords
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStaticPageFAQ = /* GraphQL */ `
  query GetStaticPageFAQ($id: ID!) {
    getStaticPageFAQ(id: $id) {
      id
      staticPageID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listStaticPageFAQs = /* GraphQL */ `
  query ListStaticPageFAQs(
    $filter: ModelStaticPageFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaticPageFAQs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        staticPageID
        title
        description
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      slug
      title
      image
      baliseTitle
      description
      keywords
      content
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        title
        image
        baliseTitle
        description
        keywords
        content
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWhoAreWe = /* GraphQL */ `
  query GetWhoAreWe($id: ID!) {
    getWhoAreWe(id: $id) {
      id
      title
      subtitle
      image
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listWhoAreWes = /* GraphQL */ `
  query ListWhoAreWes(
    $filter: ModelWhoAreWeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWhoAreWes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        image
        description
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      title
      subtitle
      image
      rating
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        image
        rating
        description
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const listFAQs = /* GraphQL */ `
  query ListFAQs(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        statusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProspect = /* GraphQL */ `
  query GetProspect($id: ID!) {
    getProspect(id: $id) {
      id
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      processStatusCode
      statusCode
      emailReminderTimes
      smsReminderTimes
      token
      verified
      createdAt
      createdAtTimestamp
      ProspectUserId
      signatureDate
      companyName
      sigle
      legalStatus
      object
      capital
      capitalMini
      capitalInitial
      duration
      greffe
      civility
      name
      firstName
      meetingAndVoteType
      meetingAndVoteDesc
      clausesDagrements
      clausesDagrementsDesc
      updatedAt
      owner
    }
  }
`;
export const listProspects = /* GraphQL */ `
  query ListProspects(
    $filter: ModelProspectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProspects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone_number
        address
        numberStreet
        additionalAddress
        zipCode
        city
        company_already_created
        packageID
        payment
        fullname
        processStatusCode
        statusCode
        emailReminderTimes
        smsReminderTimes
        token
        verified
        createdAt
        createdAtTimestamp
        ProspectUserId
        signatureDate
        companyName
        sigle
        legalStatus
        object
        capital
        capitalMini
        capitalInitial
        duration
        greffe
        civility
        name
        firstName
        meetingAndVoteType
        meetingAndVoteDesc
        clausesDagrements
        clausesDagrementsDesc
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProspectOption = /* GraphQL */ `
  query GetProspectOption($id: ID!) {
    getProspectOption(id: $id) {
      id
      prospectID
      optionCode
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProspectOptions = /* GraphQL */ `
  query ListProspectOptions(
    $filter: ModelProspectOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProspectOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prospectID
        optionCode
        statusCode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPic = /* GraphQL */ `
  query GetPic($id: ID!) {
    getPic(id: $id) {
      id
      civility
      name
      firstName
      address
      additionalAddress
      pays
      zipCode
      city
      prospectID
      code
      denomination
      type
      formeJuridique
      capital
      greffe
      rsc
      level
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPics = /* GraphQL */ `
  query ListPics(
    $filter: ModelPicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        civility
        name
        firstName
        address
        additionalAddress
        pays
        zipCode
        city
        prospectID
        code
        denomination
        type
        formeJuridique
        capital
        greffe
        rsc
        level
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProspectNote = /* GraphQL */ `
  query GetProspectNote($id: ID!) {
    getProspectNote(id: $id) {
      id
      prospectID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProspectNotes = /* GraphQL */ `
  query ListProspectNotes(
    $filter: ModelProspectNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProspectNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prospectID
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProspectUser = /* GraphQL */ `
  query GetProspectUser($id: ID!) {
    getProspectUser(id: $id) {
      id
      email
      phone
      name
      customerId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProspectUsers = /* GraphQL */ `
  query ListProspectUsers(
    $filter: ModelProspectUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProspectUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        name
        customerId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      message
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getConfigure = /* GraphQL */ `
  query GetConfigure($id: ID!) {
    getConfigure(id: $id) {
      id
      title
      structures
      createdAt
      updatedAt
    }
  }
`;
export const listConfigures = /* GraphQL */ `
  query ListConfigures(
    $filter: ModelConfigureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        structures
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      phone
      role
      email
      password
      confirm
      token
      expiredDate
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        role
        email
        password
        confirm
        token
        expiredDate
        statusCode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      name
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        statusCode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
